<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add Business
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Description"
            v-model="theBusiness.description"
            id="description"
            label="Description:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="theBusiness.type"
            id="type"
            :options="businessTypes"
            displayValue="description"
            placeholder="Business Type"
            color="gold"
            label="Select Type Of Business:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <CurrencyField
            type="text"
            placeholder="0.00"
            id="amount"
            color="gold"
            v-model="theBusiness.total"
            :currencySymbol="defaultCurrency"
            label="Enter Amount:"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{parseFloat(Math.round((calculatedAmount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="theBusiness.total < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="businessAssets.length > 0">
        <div class="col-12 bold smallheading my-4">
          Businesses
        </div>
      </div>
      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in businessAssets" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{ displayDescription(asset.businessAssetType) }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{ displayDescription(asset.businessAssetType) }}
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this businessAssets?" :message="businessAssets.description" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddBusiness',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      theBusiness: {
        description: '',
        type: {},
        total: null
      },
      isEdit: false,
      isDelete: false,
      currentKey: null,
      businessTypes: [{
        value: 'TRADE_DEBTORS',
        description: 'Trade Debtors'
      }, {
        value: 'STOCK_IN_TRANSIT',
        description: 'Stock in Transit'
      }, {
        value: 'RAW_MATERIALS',
        description: 'Raw Materials'
      }, {
        value: 'UNFINISHED_GOODS',
        description: 'Unfinished Goods'
      }, {
        value: 'LAY_BYE',
        description: 'Lay-bye'
      }, {
        value: 'STOCK_ON_HAND',
        description: 'Stock On Hand'
      }, {
        value: 'DEFECTIVE_DEAD_STOCK',
        description: 'Defective / Dead Stock'
      }, {
        value: 'CREDIT_CASH_VOUCHERS',
        description: 'Credit/Cash Vouchers'
      }, {
        value: 'ASSETS_BOUGHT_TO_RESELL',
        description: 'Any item bought to resell'
      }, {
        value: 'OTHER_BUSINESS_ASSET_TYPE',
        description: 'Other Business Assets'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'businessAssets'
    ]),
    calculatedAmount () {
      if (this.theBusiness.total) {
        return parseFloat(this.theBusiness.total).toFixed(2)
      }
      return (0).toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'addToBusiness',
      'editBusiness',
      'deleteBusinessItem',
      'clearBusiness'
    ]),
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    showDelete (key, val) {
      this.theBusiness = val
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.theBusiness = {
        description: '',
        type: {},
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deleteBusinessItem(this.currentKey)
      this.theBusiness = {
        description: '',
        type: {},
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.theBusiness = {
        description: '',
        type: {},
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.theBusiness.type.value = val.businessAssetType
      this.businessTypes.forEach((item) => {
        if (item.value === val.businessAssetType) {
          this.theBusiness.type = item
        }
      })
      this.theBusiness.description = val.description
      this.theBusiness.total = val.amount

      this.currentKey = key
    },
    async update () {
      const saveBusiness = {
        key: '',
        item: {
          description: '',
          amount: 0,
          businessAssetType: ''
        }
      }
      saveBusiness.key = this.currentKey
      saveBusiness.item.businessAssetType = this.theBusiness.type.value
      saveBusiness.item.description = this.theBusiness.description
      saveBusiness.item.amount = this.theBusiness.total
      this.editBusiness(saveBusiness)
      this.theBusiness = {
        description: '',
        type: {},
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      const saveBusiness = {
        description: '',
        amount: 0,
        businessAssetType: ''
      }
      saveBusiness.businessAssetType = this.theBusiness.type.value
      saveBusiness.description = this.theBusiness.description
      saveBusiness.amount = this.theBusiness.total
      this.addToBusiness(saveBusiness)
      this.theBusiness = {
        description: '',
        type: {},
        total: null
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
